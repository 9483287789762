<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		duration?: number;
		flip?: boolean;
	}>(),
	{ duration: 0.8, flip: false }
);

const duration = computed(() => `transform ${props.duration}s`);
</script>
<template>
	<div class="wrapper" :class="{ flip: props.flip }">
		<div class="inner">
			<div class="front">
				<slot name="front" />
			</div>
			<div class="back">
				<slot name="back" />
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.wrapper {
	perspective: 1000px;
}

.inner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: v-bind(duration);
	transform-style: preserve-3d;
}

.wrapper.flip .inner {
	transform: rotateY(180deg);
}

.front,
.back {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
}

.back {
	transform: rotateY(180deg);
}

.flip .front {
	pointer-events: none;
}
</style>
